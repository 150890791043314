import { CountryCreateDto, CountryService, CountryUpdateDto } from "@exportx/shared-models-and-services";
import { ClassValidator } from "@exportx/ui-utils";
import { Button, Card, Col, Form, Row, Space } from "antd";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, GenericFormWithOutPopUP, InputTypes, isPermissionExist, useAuthState } from "../../../../../common";
import { useEffect } from "react";

interface ICountryFormProps {
}
export const CountryForm = (props: ICountryFormProps) => {
  const navigate = useNavigate();
  const { authContext } = useAuthState();//to access global authorized user data(Login user Data which is passed as created user or updated user)
  const { state }: any = useLocation();
  const { isUpdate, countryData, isNewRecord } = state ? state : { isUpdate: false, countryData: null, isNewRecord: true };; // Read values passed on router  
  const { formatMessage: fm } = useIntl();
  const [formRef] = Form.useForm();
  const classValidator = new ClassValidator();
  //To Access Backend Services Via Shared Services
  const service = new CountryService();
  let pathToreDirect = '/countries';

  useEffect(()=>{
    const accessId = {
      create: [2],
      view: [3],
      edit: [4],
    }
    const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view;

    if(!isPermissionExist(hasPermission)){
      return navigate('/')
    }
  },[])

  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }
  const clearData = () => {
    formRef.resetFields();
  }


  const formItems = [
    {
      name: 'countryName',
      label: fm({ id: "mdm.components.countryForm.countryName", defaultMessage: 'Country Name' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.countryForm.countryNameReq", defaultMessage: 'Enter Country Name' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.countryForm.countryNameReq`, defaultMessage: "Enter Country Name" }) },

        ...classValidator.alphaSpaces(),
        ...classValidator.lengthValidation(15, fm({ id: "mdm.components.countryForm.countryName", defaultMessage: 'Country Name' }))
      ]
    },
    {
      name: 'alphaCodetwo',
      label: fm({ id: "mdm.components.countryForm.alphaCodetwo", defaultMessage: 'Alpha Code Two' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.countryForm.alphaCodetwoReq", defaultMessage: 'Enter alpha Codetwo' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.countryForm.alphaCodetwoReq`, defaultMessage: "Enter Alpha Codetwo" }) },

        ...classValidator.alphaSpaces(),
        ...classValidator.lengthValidation(2, fm({ id: "mdm.components.countryForm.alphaCodetwo", defaultMessage: 'alphaCodetwo' }))
      ]
    }
    , {
      name: 'alphaCodethree',
      label: fm({ id: "mdm.components.countryForm.alphaCodethree", defaultMessage: 'Alpha Code Three' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.countryForm.alphaCodethreeReq", defaultMessage: 'Enter alpha Codethree' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.countryForm.alphaCodethreeReq`, defaultMessage: "Enter Alpha Codethree" }) },

        ...classValidator.alphaSpaces(),
        ...classValidator.lengthValidation(3, fm({ id: "mdm.components.countryForm.alphaCodethree", defaultMessage: 'alphaCodethree' }))
      ]
    },
    {
      name: 'numbericCode',
      label: fm({ id: "mdm.components.countryForm.numbericCode", defaultMessage: 'Numberic Code' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.countryForm.numbericCodeReq", defaultMessage: 'Enter numbericCode' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.countryForm.numbericCodeReq`, defaultMessage: "Enter numbericCode" }) },

        ...classValidator.onlyNumeric(),
        ...classValidator.lengthValidation(3, fm({ id: "mdm.components.countryForm.numbericCode", defaultMessage: 'numbericCode' }))
      ]
    }
  ]




  const update = (values: any) => {
    const updateDto: CountryUpdateDto = new CountryUpdateDto(countryData.countryId, values.countryName, values.alphaCodetwo, values.alphaCodethree, values.numbericCode, authContext.user.userName, countryData.versionFlag);
    service.update(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Country Updated SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const submitForm = (values: any) => {
    if (isUpdate && countryData?.countryId) {
      update(values);
      return;
    }
    const createDto: CountryCreateDto = new CountryCreateDto(values.countryName, values.alphaCodetwo, values.alphaCodethree, values.numbericCode, authContext.user.userName);
    service.create(createDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Country Created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (
    <Card bodyStyle={{ overflow: 'auto' }} title={fm({ id: 'mdm.components.country Form.header', defaultMessage: 'Country Details' })}
      className='default-card-class'
      extra={
        <Button
          onClick={() => navigate(pathToreDirect)}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
    >
      <GenericFormWithOutPopUP
        title={fm({ id: "mdm.components.countryForm.title", defaultMessage: 'COUNTRIES' })}
        formItems={formItems}
        formRef={formRef}
        initialValues={countryData}
        isUpdate={isUpdate}
        isNewRecord={isNewRecord}
        closeForm={() => { }}
        submitForm={() => { }}
        clearData={clearData} >
        <Row justify="end">
          <Col>
            {
              (isNewRecord || isUpdate) && <Space>
                <Button
                  onClick={() => { clearData(); }}
                >
                  {fm({ id: "common.clear", defaultMessage: "Clear" })}
                </Button>
                <Button onClick={submitFormHandler} type="primary">
                  Submit
                </Button>
              </Space>
            }</Col>
        </Row>

      </GenericFormWithOutPopUP>   </Card>
  )
}

export default CountryForm;
